import CustomContainer from "./CustomContainer";
import logo from "../assets/images/logo.svg";
import gladiator_finance from "../assets/images/footer/gladiator_finance.png";
import gladiator_financeWebp from "../assets/images/footer/gladiator_finance.webp";
import gladiator_financeJp2 from "../assets/images/footer/gladiator_finance.jp2";
import gladiator_financeJxr from "../assets/images/footer/gladiator_finance.jxr";
import ImgNextGen from "./ImgNextGen";
import Svg from "./Svg";
import CustomBtn from "./CustomBtn/CustomBtn";
import { BtnType, Link, Size } from "../types";
import { useState } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import hot from "../assets/images/hot.svg";

interface IFooter {
    links: Link[];
}

export default function Footer({ links }: IFooter): JSX.Element {
    const bp768px = useMediaQuery(768);

    const [activeSmallLink, setActiveSmallLink] = useState<number>();

    const smallLinks = [
        {
            icon: <Svg name="discordSm" width={11} height={13} />,
            color: "discord",
            href: "https://discord.gg/gladiator-finance",
        },
        {
            icon: <Svg name="twitterSm" width={13} height={11} />,
            color: "twitter",
            href: "https://twitter.com/GladiatorDeFi",
        },
        {
            icon: <Svg name="medium" width={16} height={9.14} />,
            color: "black",
            href: "https://medium.com/@GladiatorDeFi/i-gladiator-finance-a-call-to-arms-de844403ee37",
        },
        {
            icon:
                <Svg
                    name="doc"
                    width={10.47}
                    height={14}
                    fill={activeSmallLink === 3
                        ? ["lightblue", "blue", "blue"]
                        : ["blue", "lightblue", "lightblue"]} />,
            color: "blue",
            href: "https://gladiator-protocol.gitbook.io/gladiator-protocol/T8QENNDx7YQm9CkauSpA/",
        },
    ]

    return (
        <footer>
            <CustomContainer color="medium" paddingTop={40} paddingBottom={20}>
                <div className="w-full">
                    <div className="flex justify-center mb-3">
                        <img src={logo} alt="" width={69.85} height={80} />
                    </div>
                    <div className="flex justify-center mb-4">
                        <ImgNextGen
                            srcWebp={gladiator_financeWebp}
                            srcJp2={gladiator_financeJp2}
                            srcJxr={gladiator_financeJxr}
                            fallback={gladiator_finance}
                            width={218}
                            height={35}
                            alt={""}
                        />
                    </div>
                    <p className="text-black text-16 sm:text-18 font-light mb-12 text-center">An algorithmic stablecoin integrated with an NFT risk protocol.</p>
                    <div className={`flex justify-center mb-16`}>
                        <nav className={"w-fit lg:flex lg:space-x-12"}>
                            {links.map((link, i) => {
                                return (
                                    <div key={i.toString()} className={"lg:flex justify-center mb-6 lg:mb-0"}>
                                        <div className="flex justify-center">
                                            <a
                                                className={`flex justify-center items-center
                                                ${link.highlighted ? "text-flame" :"text-[#8891A5]"} text-14 sm:text-16 opacity-60 
                                                ${!link.disabled ? "hover:opacity-100" : "cursor-not-allowed"} 
                                                focus:opacity-100 transition duration-300 flex items-center`}
                                                href={link.href}
                                                target={link.target}
                                                rel={link.rel}
                                            >
                                                {link.highlighted &&
                                                    <img src={hot} alt="" width={15} height={19.97} className={"mr-1"} />
                                                }
                                                {link.name}
                                            </a>
                                        </div>
                                    </div>
                                )
                            })}
                        </nav>
                    </div>
                    <div className="grid grid-cols-2 gap-8 justify-between w-full">
                        <div className="col-span-2 md:col-span-1 flex justify-center md:justify-start items-center">
                            <span className=" text-black text-14 sm:text-16">Copyright © Gladiator Finance | 2022</span>
                        </div>
                        <nav className="col-span-2 md:col-span-1 flex space-x-8 justify-center md:justify-end">
                            {smallLinks.map((link, i) => {
                                return (
                                    <CustomBtn
                                        key={i.toString()}
                                        type={BtnType.outlined}
                                        size={Size.sm}
                                        color={link.color}
                                        rounded={"rounded-[2px]"}
                                        padding={"px-0 py-0"}
                                        className={"w-[32px] h-[32px]"}
                                        asLink
                                        href={link.href}
                                        target={"_blank"}
                                        rel={"noopener noreferrer"}
                                        onMouseOver={() => setActiveSmallLink(i)}
                                        onMouseLeave={() => setActiveSmallLink(null)}
                                        onFocus={() => setActiveSmallLink(i)}
                                        onBlur={() => setActiveSmallLink(null)}
                                    >
                                        {link.icon}
                                    </CustomBtn>
                                )
                            })}
                        </nav>
                    </div>
                </div>
            </CustomContainer>
        </footer>
    )
}